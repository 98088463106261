
import React from 'react'
import Slider from "react-slick";
import Img from 'gatsby-image';
/*import { GatsbyImage } from '@wardpeet/gatsby-image-nextgen/compat';*/

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block"}}
      onClick={onClick}
    />
  );
}

class Hero extends React.Component{

  render(){
    const {img, imgMob} = this.props;
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />
    };
   
    return (
      <div className="hero">
        <Slider  {...settings}>
          {img.map( (item, i) => {
            let mob = imgMob[i];
            const sources = [
              item.node.childImageSharp.fluid,
              {
                ...mob.node.childImageSharp.fluid,
                media: "(max-width: 768px)",
              }
            ];
            return (
              <div key={i}>
                <Img loading="eager" fluid={sources}/>
              </div>
            )
          }
          )}
        </Slider>
      </div>
    );
  }

}

export default Hero
