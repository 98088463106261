import React from 'react'
import { StaticQuery, graphql  } from "gatsby"
import { Link } from '../components/localizedLink'
import { GatsbyImage } from '@wardpeet/gatsby-image-nextgen/compat';
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/hero'
import Newsletter from '../components/newsletter'
import Contattami from '../components/contattami'
import {filterByLang} from '../utility/utils'


const IndexPage = ({ pageContext: { locale } }) => (
  <StaticQuery
    query={graphql`
    query {
      hero: allFile(filter: {relativeDirectory: {eq: "hero"} }, sort: {fields: name}) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth:1240) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }    
      }
      heromob: allFile(filter: {relativeDirectory: {eq: "hero-mobile"} }, sort: {fields: name}) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth:800) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }    
      }
      intro: allContentfulContenutoGenerico(filter: {contentful_id: {eq: "58aRTDoIo0OvwkvamsZx5T"}}) {
        edges {
          node {
            titolo
            node_locale
            claim {
              childMarkdownRemark {
                html
              }
            }
            testo {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
      box1:  allContentfulContenutoGenerico(filter: {contentful_id: {eq: "4IgAWpbn36rLy1JcYRQwEm"}}) {
        edges {
          node {
            titolo
            node_locale
            testo {
              childMarkdownRemark {
                html
              }
            }
            immagine {
              fluid(maxWidth: 510) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
      }
      box2:  allContentfulContenutoGenerico(filter: {contentful_id: {eq: "3Znl66MUY84x97bTs5qECk"}}) {
        edges {
          node {
            titolo
            node_locale
            testo {
              childMarkdownRemark {
                html
              }
            }
            immagine {
              fluid(maxWidth: 510) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
        
      }
      box3:  allContentfulContenutoGenerico(filter: {contentful_id: {eq: "cQ7H3TQcmJaudREGIgJ2z"}}) {
        edges {
          node {
            titolo
            node_locale
            testo {
              childMarkdownRemark {
                html
              }
            }
            immagine {
              fluid(maxWidth: 510) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
      } 
      box4:  allContentfulContenutoGenerico(filter: {contentful_id: {eq: "1yTL9K2AwAYwdLeNVSh5p4"}}) {
        edges {
          node {
            titolo
            node_locale
            testo {
              childMarkdownRemark {
                html
              }
            }
            immagine {
              fluid(maxWidth: 510) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
      }
      box5:  allContentfulContenutoGenerico(filter: {contentful_id: {eq: "3CvHw1jnw1HVFoBSrnRZeB"}}) {
        edges {
          node {
            titolo
            node_locale
            testo {
              childMarkdownRemark {
                html
              }
            }
            immagine {
              fluid(maxWidth: 510) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
      } 
      box6:  allContentfulContenutoGenerico(filter: {contentful_id: {eq: "3i5sx9MKP7xwPU2psUoYLj"}}) {
        edges {
          node {
            titolo
            node_locale
            testo {
              childMarkdownRemark {
                html
              }
            }
            immagine {
              fluid(maxWidth: 510) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
      } 
    }
    `} render={data => {
      const img = data.hero.edges;
      const imgMob = data.heromob.edges;
      const intro = filterByLang(data.intro,locale)[0];
      const box1 = filterByLang(data.box1,locale)[0];
      const box2 = filterByLang(data.box2,locale)[0];
      const box3 = filterByLang(data.box3,locale)[0];
      const box4 = filterByLang(data.box4,locale)[0];
      const box5 = filterByLang(data.box5,locale)[0];
      const box6 = filterByLang(data.box6,locale)[0];
      return (
        <Layout locale={locale}>
          <SEO title="Giulia Clerici | Food illustration & more" 
            descritpion="Ciao, benvenuto! Mi chiamo Giulia e sono un'illustratrice amante di acquerelli, cucina e mondi fantastici"
            forcetitle="true" keywords={[`acquerello`, `ricette`,'infanzia','packaging','illustrazioni']} 
            lang="it"/>
    
          <div className="section">
            <div className="row">
              <div className="col-12 text-center">
                <div  dangerouslySetInnerHTML={{__html: intro.claim.childMarkdownRemark.html}} />
              </div>
            </div>
          </div>
        
          <Hero img={img} imgMob={imgMob}/>

          <div className="section section--top">
            <div className="row">
              <div className="col-12 text-center">
                {!!intro.titolo && <div className="section__title">{intro.titolo}</div>}
                <div  dangerouslySetInnerHTML={{__html: intro.testo.childMarkdownRemark.html}} />
              </div>
            </div>
          </div>  
          
          <div className="section section--pt">
              {/*
              <div className="row">
                <div className="col-12 text-center">
                  <h1 className="section__title">Cosa posso fare per te</h1>
                </div>
              </div>
              */} 
            <div className="row">
              <div className="col-md-4">
                {!!box1.immagine && 
                  <Link className="imghover" title="Cosa posso fare per te" to="/cosa-faccio/">
                    <GatsbyImage  fluid={box1.immagine.fluid} />
                  </Link>
                }
                <h2 className="section__subtitle">
                  <Link title={box1.titolo} to="/cosa-faccio/">{box1.titolo}</Link>
                </h2>
                <div className="section__text" dangerouslySetInnerHTML={{__html: box1.testo.childMarkdownRemark.html}}>
                </div>
              </div>  
              <div className="col-md-4">
                {!!box2.immagine &&
                  <Link className="imghover" to="/i-miei-lavori/" title="Guarda i miei lavori">
                    <GatsbyImage  fluid={box2.immagine.fluid} />
                  </Link>
                }
                <h2 className="section__subtitle">
                  <Link to="/i-miei-lavori/" title={box2.titolo}>{box2.titolo}</Link>
                </h2>
                <div className="section__text" dangerouslySetInnerHTML={{__html: box2.testo.childMarkdownRemark.html}}>
                </div>
              </div>  
              <div className="col-md-4">
                {!!box3.immagine && 
                  <Link className="imghover" title={box3.titolo} to={`${locale==='it'?'/shop/':'https://www.etsy.com/it/shop/RicetteDisegnate'}`}>
                    <GatsbyImage  fluid={box3.immagine.fluid} />
                  </Link>
                }
                <h2 className="section__subtitle">
                  <Link to={`${locale==='it'?'/shop/':'https://www.etsy.com/it/shop/RicetteDisegnate'}`} title={box3.titolo}>{box3.titolo}</Link>
                </h2>
                <div className="section__text" dangerouslySetInnerHTML={{__html: box3.testo.childMarkdownRemark.html}}>
                </div>
              </div>  
            </div>
            {locale === 'it' && false &&
            <div className="row mt-5">
              <div className="col-md-4">
                {!!box4.immagine && 
                  <Link className="imghover" title="Corsi di acqurello" to="/corsi/">
                    <GatsbyImage  fluid={box4.immagine.fluid} />
                  </Link>
                }
                <h2 className="section__subtitle">
                  <Link title={box4.titolo} to="/corsi/">{box4.titolo}</Link>
                </h2>
                <div className="section__text" dangerouslySetInnerHTML={{__html: box4.testo.childMarkdownRemark.html}}>
                </div>
              </div>  
              <div className="col-md-4">
                {!!box5.immagine && 
                  <Link className="imghover" title="Blog" to="/blog/">
                    <GatsbyImage  fluid={box5.immagine.fluid} />
                  </Link>
                }
                <h2 className="section__subtitle">
                  <Link title={box5.titolo} to="/blog/">{box5.titolo}</Link>
                </h2>
                <div className="section__text" dangerouslySetInnerHTML={{__html: box5.testo.childMarkdownRemark.html}}>
                </div>
              </div>
              <div className="col-md-4">
                {!!box6.immagine && 
                  <Link className="imghover" title="Blog" to="/blog/">
                    <GatsbyImage  fluid={box6.immagine.fluid} />
                  </Link>
                }
                <h2 className="section__subtitle">
                  <Link title={box6.titolo} to="/blog/">{box6.titolo}</Link>
                </h2>
                <div className="section__text" dangerouslySetInnerHTML={{__html: box6.testo.childMarkdownRemark.html}}>
                </div>
              </div>
            </div>
            }
          </div>
          <Contattami action="contattami"/>
          <Newsletter/>
          
        </Layout>
       )
    }}
  />
)

export default IndexPage
